import React from "react";

const Logger = ({message}) => {

    return (
        <>
            <div className={"text-info dc-container-center"}>
                {new Date().toLocaleDateString("en-GB", {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: "numeric",
                minute: "numeric",
                second: "numeric"
            }) + ': ' + message}</div>
        </>
    );
}
export default Logger;