import React, {useEffect, useState} from 'react'
import {Loader, Message} from "../../components";
import VerifyService from "../../services/verify.service";
import './verify-page.style.scss'
import {createMessage} from "../../utils/functions";

const VerifyPage = () => {
    const [message, setMessage] = useState(null)
    const [response, setResponse] = useState(null)
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (response) {
            let message;
            let type = response.status === 'VERIFICATION_SUCCESS' ? 'success' : 'warning';
            if (response.status === 'VERIFICATION_SUCCESS')
                message = createMessage(
                    "Verification success",
                    response.message,
                    type)
            if (response.status === 'VERIFICATION_FAILED')
                message = createMessage(
                    "Verification failed",
                    response.message,
                    type)
            setMessage(message)
            setLoading(false)
        }
    }, [response])

    const handleSubmit = (event) => {
        // Prevent default form submit with refresh of the page
        event.preventDefault()
        setLoading(true)

        // Prepare data for the request
        const email = event.target[0].value
        const file = event.target[1].files[0]
        const formData = new FormData();
        formData.append("file", file, file.name)

        // Send request using data
        VerifyService.verifyByMail(formData, email)
            .then(response => setResponse(response))
            .catch((error) => {
                setLoading(false)
                setMessage(
                    createMessage(
                        'Not verified',
                        "There is an error! The verification process was interrupted: " + error.message,
                        'error'))
            });
    }

    return (
        <>
            <h1 className={'verify-title'}>Verify document</h1>
            <div className={'dc-content-container'}>
                {isLoading
                    ? <div className={'dc-container-center'}><Loader/></div>
                    : message
                        ? <div className={'dc-container-center'}>
                            <Message messageTitle={message.title}
                                     messageText={message.text}
                                     messageTypeClass={message.type}/></div>
                        : <div className={'verify-form-container'}>
                            <form onSubmit={handleSubmit}>
                                <div className={"mb-3"}>
                                    <label htmlFor={'emailInput'}>Email address</label>
                                    <input className={'form-control'} id={'emailInput'} type={'email'}
                                           placeholder={'Write the email of the document owner'}/>
                                </div>
                                <div className={"mb-3"}>
                                    <label htmlFor={'fileInput'}>File input</label>
                                    <input className={'form-control'} id={'fileInput'} type={'file'}/>
                                </div>
                                <div className={'button-container'}>
                                    <button className={'btn dc-btn'} type={'submit'}>Verify</button>
                                </div>
                            </form>
                        </div>}
            </div>
        </>
    )
}

export default VerifyPage