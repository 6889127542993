import {Search} from "../../../index";
import '../dropdowns.styles.css'
import {useEffect, useState} from "react";

const Dropdown = ({list, lable, name, handleChange}) => {
    /*States*/
    const [searchString, setSearchString] = useState('');
    const [selected, setSelected] = useState()
    const [show, setShow] = useState(false);
    const [filtered] = useState(false)
    const [filteredList, setFilteredList] = useState([])


    /*Effects*/
    useEffect(() => {
        setFilteredList(() => list)
    }, [])
    useEffect(() => {
        if (filtered) setFilteredList(() => selected)
        else setFilteredList(list.filter((item) => item.name.toLowerCase().includes(searchString)))
    }, [filtered, searchString])
    useEffect(() => {
        handleChange(name, selected)
    }, [selected])

    /*Functions*/

    const filterSearch = (event) => {
        const searchString = event.target.value.toLowerCase();
        setSearchString(searchString);
    }
    const handleChecked = (item) => {
        setSelected(() => item.name);
    }
    const handleFocusOut = (event) => {
        if (event.relatedTarget === null || event.currentTarget.className === event.relatedTarget.className)
            setShow(false);
    }


    /*JSX*/
    return (
        <div  className={"dropdown-layout"} tabIndex={0} onBlur={handleFocusOut}>
            <label className={"form-label"} >{lable}</label>
            <div className={"form-select dropdown-input"}
                 onClick={() => setShow(true)}>{selected}</div>
            {
                show &&
                <div className={"dropdown-search-container"} >
                    <div className={"search-container"}>
                        <Search onSearch={filterSearch}/>
                    </div>
                    <div className={"dropdown-container"}>
                        <div className={"select-field"}  >
                            {filteredList.map((item) => {
                                return (
                                    <div className={"option-field"} key={item.id}>
                                        <option className={"option-box"}
                                                id={item.id}
                                                key={item.id}
                                                name={name}
                                                onClick={() => {
                                                    handleChecked(item)
                                                }}>{item.name}</option>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default Dropdown;