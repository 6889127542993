import {Approver} from "../models/Approver";
import {ApproverGroup} from "../models/ApproverGroup";

function mapApproverDTO(approverList){
    return approverList.map(item => new Approver(item.id,item.name))
}
function mapApproverGroupDTO(approverGroupList){
    return approverGroupList.map(item => new ApproverGroup(item.id, item.name))
}
const DTO = {mapApproverDTO, mapApproverGroupDTO}
export default DTO;