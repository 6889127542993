import {FcUpload} from "react-icons/fc";
import styles from "./input-file.module.css";
import {AiFillCloseCircle} from "react-icons/ai";
import React, {useRef, useState} from "react";

/**
 * In HTML, binding the label with the input
 * and putting the label and the button in the same range,
 * will give the label the same focus at onClick as the input, so it
 * does not matter if I click on the label or the button.
 * */
const InputFile = ({setFile}) => {

    const [loadFile, setLoadFile] = useState(null);
    const contentRef = useRef(null);

    const onDragEnter = () => contentRef.current.classList.add(styles.dragged);
    const onDragLeave = () => contentRef.current.classList.remove(styles.dragged);
    const onDrop = () => {
        contentRef.current.classList.remove(styles.dragged);
        onFileDrop()
    }
    const onFileDrop = (event) => {
        const newFile = event.target.files[0];

        if (newFile) {
            setLoadFile(newFile);
            setFile(newFile)
        }
    };
    const removeFile = () => {
        setLoadFile(null)
        document.getElementById("upload-file").value = null
    };

    return (
        <div>
            <div className={styles.contentLayout}
                 onDragEnter={onDragEnter}
                 onDragLeave={onDragLeave}
                 onDrop={onDrop}
                 ref={contentRef}>
                <div className={"bigIcon " + styles.uploadIcon}><FcUpload/></div>
                <input id={"upload-file"}
                       className={styles.fileUploadDrag}
                       type={"file"}
                       onChange={onFileDrop}
                       required/>
                <label htmlFor={"upload-file"} className={styles.uploadLabel}>Drag your file in here or click here to
                    select a file for upload</label>
            </div>
            {
                loadFile &&
                <div className={styles.fileContainer}>
                    <div className={styles.fileItem}>
                        <span>{loadFile.name}</span>
                        <span className={styles.removeIcon}
                              onClick={removeFile}><AiFillCloseCircle/></span>
                    </div>
                </div>
            }
        </div>
    );
}

export default InputFile;