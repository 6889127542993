import {Routes} from "react-router";
import {LandingPage, ErrorPage} from "./pages/index";
import {BrowserRouter, Route} from "react-router-dom";
import {
    DebugPage,
    HomePage,
    VerifyPage,
    VerifyListPage,
    ProfilePage,
    ApprovePage,
    DownloadPage,
    SharedLayout,
    UploadPage,
} from "./pages";
import {UserProvider} from "./contexts/user.context";
// AWS Authentication
import {groups} from "./utils/constants/groups.enum";
import '@aws-amplify/ui-react/styles.css';
import {RequireAuth} from "./components";
import Login from "./components/security/Login/login.component";

function App() {
    return (
        <BrowserRouter forceRefresh={true}>
                    <UserProvider>
                        <Routes>
                            <Route path="/" element={<SharedLayout/>}>
                                <Route index element={<HomePage/>}/>
                                <Route path="profile" element={<ProfilePage/>}/>
                                <Route path={"login"} element={<Login/>}/>
                                <Route path="approve" element={
                                    <RequireAuth allowedGroups={groups.OUSER}>
                                        <ApprovePage/>
                                    </RequireAuth>}/>
                                <Route path="download"
                                       element={
                                    <RequireAuth allowedGroups={groups.OWNER}>
                                        <DownloadPage/>
                                    </RequireAuth>}/>
                                <Route path="upload" element={
                                    <RequireAuth allowedGroups={groups.OUSER}>
                                        <UploadPage/>
                                    </RequireAuth>}/>
                                <Route path="verify" element={<VerifyPage/>}/>
                            </Route>
                            <Route path="debug" element={<DebugPage/>}/>
                            <Route path="out" element={<LandingPage/>}/>
                            <Route path="verified/:uuid" element={<VerifyListPage/>}/>
                            <Route path="denied" element={<ErrorPage/>}/>
                        </Routes>
                    </UserProvider>
        </BrowserRouter>
    );
}

export default App;