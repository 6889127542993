import ApiService from "./http.service";
import {setConfigurationWithAuthorization} from "../utils/functions";

/*Request config*/
let config = {
    api: process.env.REACT_APP_API_APPROVAL_SERVICE_PROD,
    options: {
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,X-Amz-Security-Token,Authorization,X-Api-Key,X-Requested-With,Accept,Access-Control-Allow-Methods,Access-Control-Allow-Origin,Access-Control-Allow-Headers",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT",
            "X-Requested-With": "*"
        }
    }
};
/*http requests*/
const getApprovers = (approverId, token = null) => {
    const endpoint = "/approval/documents?approverId="
    let configuration = setConfigurationWithAuthorization(config, token);
    return ApiService.httpGet(endpoint + approverId, configuration)
}
const setApprovers = (data, token = null) => {
    const endpoint = "/approval/assigne-approver-status"
    let configuration = setConfigurationWithAuthorization(config, token);
    return ApiService.httpPost(endpoint, data, configuration)
};

/*export methods*/
const ApproveService = {setApprovers, getApprovers};

export default ApproveService;