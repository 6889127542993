import {AuthenticatedUser} from "../models/AuthenticatedUser";
import {useContext} from "react";
import {UserContext} from "../contexts/user.context";

export function useAuthUser() {
    const {user} = useContext(UserContext)

    let authUser = user;
    let token = '';

    if (user) {
        // Set expired
        const expiresAt = user.getSignInUserSession().getAccessToken().payload['exp'] * 1000
        const now = Date.now()
        const expired = now - expiresAt > 0

        // Set groups
        const cognitoGroups = user.getSignInUserSession().getAccessToken().payload["cognito:groups"];

        // Set token
        token = user.getSignInUserSession().getAccessToken().getJwtToken()

        // Create Object from Model
        authUser = new AuthenticatedUser(
            user.attributes.sub,
            user.attributes.given_name,
            user.attributes.family_name,
            user.attributes.email,
            user.username,
            cognitoGroups,
            expired)
    }

    return [authUser, token];
}