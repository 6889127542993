import styles from './input-text.module.css';

const InputText = ({label, name, placeholder, handleData, type}) => {
    return (
        <div  className={styles.inputLayout}>
            <label for={"validation"}  className={"form-label"}>{label}</label>
            <input  id={"validation"}   name={name} type={type} className={"form-control"} onChange={handleData} placeholder={placeholder} required/>
        </div>
    );
}
export default InputText