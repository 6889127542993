import {Message} from "../../../components";
import {Auth} from "aws-amplify";
import {useContext, useState} from "react";
import {useNavigate} from "react-router";
import {UserContext} from "../../../contexts/user.context";

const SessionExpired = () => {
    const {setUser} = useContext(UserContext)
    const [LogOut, setLogOut] = useState(false)
    const navigate = useNavigate()

    // Logout after 5 seconds
    setTimeout(() => Auth.signOut().then(() => setLogOut(true)), 5000)

    if (LogOut) {
        navigate("/")
        setUser(null)
    }

    return (
        <>
            <div className={"container d-flex justify-content-center mt-5"}>
                <Message messageTitle={"Session Expired"}
                         messageText={"The Session has expired. You will be logged out in 5 sec"}
                         messageTypeClass={"warning"}/>
            </div>
        </>
    );
}
export default SessionExpired;