export const files = [
    {
        "metaDataInfo": {
            "email": "ireclame@live.de",
            "firstName": "Bill",
            "lastName": "Gates",
            "institution": "Universität Leipzig",
            "typeOfCertification": "Bachelor"
        },
        "presignedURL": "https://swo-prod-dc-s3-eu-west-1.s3.eu-west-1.amazonaws.com/1664955857357_bill.gates_phd.docx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221005T074538Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604799&X-Amz-Credential=AKIA3EAD6UUFJHOPF2VC%2F20221005%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Signature=cd89a80aa49b0b4e2f58c1ca740096f3d200974e5ac00ccb4382251b2c8f1a84",
        "filenamePresignedURL": "1664955857357_bill.gates_phd.docx"
    }
]