import React from 'react'
import ConstructionPage from "../Landing/UnderContruction/construction-page.component";

const ProfilePage = () => {
  return (
    <div>
        <h1>Profile</h1>
        <ConstructionPage/>
    </div>
  )
}

export default ProfilePage