import React from "react";
import {Outlet} from "react-router";
import Header from "../../../components/Header/header.component";
import "./shared-layout.style.scss"

const ShareLayout = () => {
    return (
        <>
            <header className={"header-container"}><Header/></header>
            <main className={"container"}>
                <div className={"header-placeholder"}/>
                <Outlet/>
            </main>
        </>
    );
};

export default ShareLayout;