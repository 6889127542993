import {createContext, useState} from "react";
import {Auth} from "aws-amplify";

// Initialize the context exported.
export const UserContext = createContext({
    user: null
})
// Provide the context with values
export const UserProvider = ({children}) => {
    /*States*/
    const [user, setUser] = useState(null)
    const [catchAuthReason, setCatchAuthReason] = useState(null);

    if (!user)
        Auth.currentAuthenticatedUser()
            .then(user => setUser(user))
            .catch((reason) => setCatchAuthReason(reason))

    /*Constants*/
    const value = {user, setUser, catchAuthReason}
    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}