import styled from "styled-components";
import {FcMediumPriority} from "react-icons/fc";

const ConstructionPage = () => {
    const PageStyle = styled.main`
        color: red;
        font-size: larger;
        
        .pictogram{
                font-size: 5rem;
        }
    `;

  return(
      <PageStyle>
          <p>
              <FcMediumPriority className="pictogram"/>
              Page under construction. . .
          </p>
      </PageStyle>
  );
}
export default ConstructionPage;