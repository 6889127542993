import {Search} from "../../../index";
import '../dropdowns.styles.css'
import {useEffect, useState} from "react";

const DropdownChoice = ({list, lable, name, handleChange}) => {
    /*States*/
    const [searchString, setSearchString] = useState('');
    const [selected, setSelected] = useState([])
    const [show, setShow] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false)
    const [workList, setWorkList] = useState([])
    const [filteredList, setFilteredList] = useState([])
    /*Effects*/
    useEffect(() => {
        const items = list.map(item => {
            const values = Object.values(item)
            return {id: values[0], name: values[1]}
        })
        setWorkList(()=>items)
    }, [list])

    useEffect(()=>{setFilteredList(()=>workList)},[workList])

    useEffect(()=>{},[filteredList])
    useEffect(() => {
        if (isFiltered) setFilteredList(selected)
        else setFilteredList(workList.filter((item) => item.name.toLowerCase().includes(searchString)))
    }, [isFiltered, searchString])

    useEffect(() => {
        // send the selected to upper component for sending
        handleChange(name, selected)
    }, [selected])

    /*Functions*/
    const filterSearch = (event) => {
        const searchString = event.target.value.toLowerCase();
        setSearchString(searchString);
    }
    const handleChecked = (event, item) => {
        const id = event.target.value
        if (event.target.checked) {
            setSelected((prevState) => [...prevState, item]);
        } else {
            // remove from selected list
            setSelected((prevState) => prevState.filter(value => value.id !== id))
        }
    }
    const handleFocusOut = (event) => {
        if (event.relatedTarget === null || event.currentTarget.className === event.relatedTarget.className)
            setShow(false);
    }

    function resetAll() {
        setSelected(() => [])
        setIsFiltered(false)
    }

    function filterChecked() {
        isFiltered ? setIsFiltered(false) : setIsFiltered(true)
    }

    /*JSX*/
    return (
        <div className={"dropdown-layout"} tabIndex={0} onBlur={handleFocusOut}>
            <label className={"form-label"}>{lable}</label>
            <div className={"form-select dropdown-input"} onClick={() => setShow(true)}>
                {selected.map((value, index, array) => {
                    return value.name + (array.length > 1 && array.length > index+1 ? ', ' : '')
                })}
            </div>
            {
                show &&
                <div className={"dropdown-search-container"}>
                    <div className={"search-container"}>
                        <Search onSearch={filterSearch}/>
                    </div>
                    <div className={"filter-container"}>
                        <span className={"filter-item"} onClick={resetAll}>Reset</span>
                        <div>Filter: <span className={isFiltered ? "badge rounded-pill text-bg-dark" : "badge rounded-pill text-bg-light"}
                                           onClick={filterChecked}>Checked</span></div>

                    </div>
                    <div className={"dropdown-container"} >
                        <div className={"select-field"}>
                            {filteredList.map((item) => {
                                return (
                                    <div className={"option-field"} key={item.id}>
                                        <input className={"option-box"}
                                               type={"checkbox"}
                                               id={item.id}
                                               value={item.id}
                                               name={item.name}
                                               onChange={(event) => {
                                                   handleChecked(event, item)
                                               }}
                                               checked={selected.filter(value => (value.name === item.name)).length > 0}
                                        />
                                        <label htmlFor={item.id}>{item.name}</label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default DropdownChoice;