import {Navigate} from "react-router";
import {useAuthUser} from "../../../hooks/requests";
import {SessionExpired} from "../../../pages";
import {useContext} from "react";
import {UserContext} from "../../../contexts/user.context";

const RequireAuth = ({children, allowedGroups}) => {
    const [authUser] = useAuthUser()
    const {catchAuthReason} = useContext(UserContext)

    // User not loaded, i.e. still null
    if (!authUser)
        if (catchAuthReason) // User is loaded but there is an error, i.e. no authenticated
            return <Navigate to="/denied" replace/>;
    else return;
    // User expired
    if(authUser.expired) return <SessionExpired/>
    // User in a group
    if (authUser.groups.length > 0 && authUser.groups.includes(allowedGroups)) return children

    // User is loaded, not expired and in no group then it has no rights
    return <Navigate to="/denied" replace/>;
}

export default RequireAuth;