import React from "react";
import logo_color from "../../assets/images/logo.svg";
import logo_white from "../../assets/images/logo-white.svg";

const Logo = ({white = false}) => {
  return (
    <div className="logo-image">
        <img src={white ? logo_white : logo_color} alt="digital cognate" className="logo"/>
    </div>
  );
};

export default Logo;