import ApiService from "./http.service";
import {setConfigurationWithAuthorization} from "../utils/functions";

const config = {
    api: process.env.REACT_APP_API_VERIFY_SERVICE_PROD,
    options: {
        headers: {
            'Content-Type': 'application/json'
        }
    }
}

function geneneratePresignedURL(data, uuid, token = null) {
    const endpoint = '/verify/document/link/' + uuid
    let configuration = setConfigurationWithAuthorization(config, token);
    return ApiService.httpPost(endpoint, data, configuration)
}

function getOwnerDocuments(param, token = null) {
    const endpoint = '/verify/document/link/' + param;
    let configuration = setConfigurationWithAuthorization(config, token);
    return ApiService.httpGet(endpoint, configuration)
}

function verifyByMail(data, email) {
    const endpoint = '/verify/document?email=' + email
     let configuration = {
            ...config,
            options: {
                headers: {}
            }
        }
    return ApiService.httpPostData(endpoint, data, configuration)
}

const VerifyService = {geneneratePresignedURL, getOwnerDocuments, verifyByMail}

export default VerifyService