import React, {useState, useEffect} from "react";
import {InputText, Loader, Message, InputFile, Logger} from "../../components";
import styles from './upload-page.module.css'
import UploadService from "../../services/upload.service";
import Dropdown from "../../components/fields/dropdowns/Dropdown/dropdown.component";
import DropdownChoice from "../../components/fields/dropdowns/DropdownChoice/dropdown-choice.component";
import institutions from "../../utils/init/institutions";
import certifications from "../../utils/init/certifications";
import FileService from "../../services/file.service";
import DTO from "../../services/dto.service";
import {useAuthUser} from "../../hooks/requests";
import {createLog, createMessage} from "../../utils/functions";

const UploadPage = () => {
    /*States*/
    const [file, setFile] = useState(new File([], "", undefined));
    const [data, setData] = useState(null);
    const [message, setMessage] = useState(null);
    const [isLoading, setLoading] = useState(false)
    const [approvers, setApprovers] = useState(null);
    const [approverGroups, setApproverGroups] = useState(null);
    const [, token] = useAuthUser()
    const [log, setLog] = useState(null)
    const [valid, setValid] = useState(false)
    /**Fetch Approvals*/
    useEffect(() => {
        setLoading(true)
        UploadService.getApprovals(token)
            .then(approvers => {
                setTimeout(()=>setApprovers(approvers),4000)
                setLog(createLog("Approver List is loaded"))
            })
            .catch(error => {
                setLoading(false)
                setMessage(
                    createMessage(
                        'Not Found',
                        `Approvers were not loaded \n + ${error.message}`,
                        'error'
                    ))
            });
        UploadService.getApprovalGroups(token)
            .then(approverGroups => {
                setTimeout(()=>setApproverGroups(approverGroups),4000)
                setTimeout(()=>setLog(createLog("Approver Group List is loaded")),2000)
            })
            .catch(error => {
                setLoading(false)
                setMessage(
                    createMessage(
                        'Not Found',
                        "Approver Groups were not loaded: " + error.message,
                        'error'))
            });

    }, []);
    useEffect(()=>{
        if(approvers && approverGroups) {
            setLoading(false)
            setLog(null)
        }
    },[approvers,approverGroups])

    useEffect(() => {
        if (data)
            setValid(handleValidation())
    }, [data])

    useEffect(()=>{},[log])
    /*Handlers*/
    const handleInput = (event) => {
        const {name, value} = event.target
        setData(prevState => ({
            ...prevState, [name]: value
        }))
    };
    const handleChoice = (name, selected) => {
        setData((prevState) => {
            return {...prevState, [name]: selected}
        })
    }

    function hasWhiteSpace(s) {
        return (/\s/).test(s);
    }

    const handleSubmit = async (event) => {
        /* Pre-Configure Submit*/
        event.preventDefault()
        if(valid){
        setLoading(true)
        /*Set JSON data*/
        const encodedFile = await FileService.toBase64(file)
        const uploadJSON = {
            documentApproverGroupList: DTO.mapApproverGroupDTO(data.documentApproverGroupList),
            documentApproverList: DTO.mapApproverDTO(data.documentApproverList),
            metadataInfo: {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                institution: data.institution,
                typeOfCertification: data.typeOfCertification
            },
            encodedFileContent: encodedFile.replace(/data:.*base64,/i, ""),
            fileName: file.name
        }

        /*Post data*/
        UploadService.postDocument(uploadJSON, token)
            .then((result) => {
                const message = hasWhiteSpace(result)
                    ? createMessage(
                        'Not sent',
                        "Your data has not been sent: " + result,
                        'warning')
                    : createMessage(
                        'Success',
                        'Your data has been sent successfully!',
                        'success')
                setTimeout(() => {
                    setMessage(message)
                    setLoading(false);
                }, 1000)
            })
            .catch((error) => {
                setMessage(
                    createMessage(
                        'Data not sent',
                        `Your data is not sent! There is an error: ${error}`,
                        'error'))
                setLoading(false);
            });
    }
    };
    const handleValidation = () => {
        let compulsoryFields = data.institution === undefined || data.typeOfCertification === undefined || (data.documentApproverList.length <= 0 && data.documentApproverGroupList.length <= 0)
        if (compulsoryFields) {
            return false
        }
        return true
    };
    /*JSX*/
    return (
        <>
            <div className={styles.uploadPageContainer}>
                <div className={styles.uploadPageContentContainer}>
                    <h1 className={'title'}>Upload form</h1>
                    <div className={"content-container"}>
                        {isLoading
                            ? <div>
                                <Loader/>
                                {log && <Logger message={log.message}/>}</div>
                            : message
                                ? <div className={"message dc-container-center"}>
                                    <Message messageTitle={message.title}
                                             messageText={message.text}
                                             messageTypeClass={message.type}/></div>
                                : approvers
                                && approverGroups
                                && <form className={"form"} onSubmit={handleSubmit}>
                                    <>
                                        <div className={'row'}>
                                            <div className={'col-6'}>
                                                <InputText label={"First Name"} name={"firstName"}  type={"text"}
                                                           handleData={handleInput}/>
                                                <InputText label={"Last Name"} name={"lastName"} type={"text"}
                                                           handleData={handleInput}/>
                                                <InputText label={"Email"} name={"email"} type={"email"}
                                                           handleData={handleInput}/>
                                                <Dropdown
                                                    list={institutions}
                                                    lable={"Institution"}
                                                    name={"institution"}
                                                    handleChange={handleChoice}/>
                                                <Dropdown lable={"Type of Certification"}
                                                          name={"typeOfCertification"}
                                                          list={certifications}
                                                          handleChange={handleChoice}/>
                                                <DropdownChoice list={approvers}
                                                                name={"documentApproverList"}
                                                                lable={"Approver"}
                                                                handleChange={handleChoice}/>
                                                <DropdownChoice list={approverGroups}
                                                                name={"documentApproverGroupList"}
                                                                lable={"Approver Groups"}
                                                                handleChange={handleChoice}/>
                                            </div>
                                            <div className={'col-6'}>
                                                <InputFile setFile={setFile}/>
                                            </div>
                                            <div className={'button-container upload-button-container'}>
                                                <div className={'text-danger '}>
                                                    {!valid ? "Institution, Certification and Approver or Approver Group must be selected" : ""}
                                                </div>
                                                <button className={'dc-btn'} type={"submit"} disabled={!valid}>Submit</button>
                                            </div>
                                        </div>
                                    </>
                                </form>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default UploadPage;