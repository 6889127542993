import styles from './message.module.css'
import {
    BsExclamationTriangleFill,
    BsFillBootstrapFill,
    BsFillCheckCircleFill,
    BsFillExclamationCircleFill
} from "react-icons/bs";

const Message = ({messageTitle, messageText, messageTypeClass}) => {
    let alertType;
    let alertIcon;
    switch(messageTypeClass) {
        case 'success':
            alertType = 'alert-success';
            alertIcon = <BsFillCheckCircleFill/>;
            break;
        case 'warning':
            alertType = 'alert-warning';
            alertIcon = <BsExclamationTriangleFill/>;
            break;
        case 'error':
            alertType = 'alert-danger';
            alertIcon = <BsFillExclamationCircleFill/>;
            break;
        default:
            alertType = 'alert-dark'
            alertIcon = <BsFillBootstrapFill/>
    }

    return (
        <div
            className={styles.messageContainer + ' alert ' + alertType} role={"alert"}>
            <h5 className={' alert-heading '}>{messageTitle}</h5>
            <div className={styles.messageTextContainer}>
                <span className={styles.pictogram}>{alertIcon}</span>
                <span>{messageText}</span>
            </div>
        </div>)
}
export default Message;