/*requests*/
const httpGet = (endpoint, config) => {
  return fetch(`${config.api}${endpoint}`, {
    ...config.options,
  })
    .then((response) => handleResponse(response))
    .catch((error) => {
      throw Error(error);
    });
};

const httpPost = (endpoint, data, config) => {
  return fetch(`${config.api}${endpoint}`, {
    method: "POST",
    body: data ? JSON.stringify(data) : null,
    ...config.options,
  })
    .then((response) => handleResponse(response))
    .catch((error) => {
      throw Error(error);
    });
};

const httpPostData = (endpoint, data, config) => {
  return fetch(`${config.api}${endpoint}`, {
    method: "post",
    body: data,
    ...config.options
  })
    .then((response) => handleResponse(response))
    .catch((error) => {
      throw Error(error);
    });
};

const httpPut = (endpoint, data, config) => {
  return fetch(`${config.api}${endpoint}`, {
    method: "put",
    body: data ? JSON.stringify(data) : null,
    ...config.options,
  })
    .then((response) => handleResponse(response))
    .then((response) => response)
    .catch((error) => {
      throw Error(error);
    });
};

const httpDelete = (endpoint, config) => {
  return fetch(`${config.api}${endpoint}`, {
    method: "delete",
    ...config.options,
  })
    .then((response) => handleResponse(response))
    .then((response) => response)
    .catch((error) => {
      throw Error(error);
    });
};

/*handlers*/
const handleResponse = (response) => {
  switch (response.status) {
    case 200:
      return response.json();
    case 201:
      return getBody(response);
    case 400:
      throw Error(response.statusText);
    default:
      throw Error(response.statusText);
  }
};

async function getBody(response) {
  const text = await response.text();
  try {
    return JSON.parse(text);
  } catch (err) {
    return text;
  }
}

/*export*/
const ApiService = { httpGet, httpPost, httpPut, httpDelete, httpPostData };
export default ApiService;