import './header.style.scss';
import Navigation from "../Navigation/navigation.component";
import UserPicture from "../UserPicture/user-picture.component";
import Menu from '../Menu/menu.component'
import {useState} from "react";
import {AiFillCloseCircle, AiOutlineMenu} from "react-icons/ai";
import {Logo} from "../index";
import {useAuthUser} from "../../hooks/requests";
import {Link} from "react-router-dom";

const Header = () => {
    const [active, setActive] = useState(false);
    const [authUser] = useAuthUser()

    const toggleMobileMenu = () => {
        setActive(!active);
    }
    return (
        <div className={active ? "nav-container-active" : "nav-container"}>
            <div className={"logo-container"}>
                <Logo white={true}/>
                <span className={"mobileMenu"} onClick={toggleMobileMenu}>
                    {active
                        ? <AiFillCloseCircle/>
                        : <AiOutlineMenu/>}
                </span>
            </div>
            <div className={"links"}>
                <div className={active ? "links-container-active" : "links-container"}>
                    <Navigation/>
                </div>
                <div className={active ? "user-container-active" : "user-container"}>
                    {authUser ?
                    <div className={'header-user-picture'}>
                        <UserPicture/>
                        <div className={'header-user-menu'}><Menu/></div>
                    </div>
                    : <div className={'btn btn-warning'}><Link className={"link"} to={"/login"}>Login</Link></div>}
                </div>
            </div>
        </div>
    );
}

export default Header;