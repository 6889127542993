import styles from "./menu.module.css";
import {Link} from "react-router-dom";
import {BsFillPersonLinesFill, BsFillUnlockFill} from "react-icons/bs";
import {useAuthUser} from "../../hooks/requests";
import {Auth} from "aws-amplify";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";

const Menu = () => {
    const [authUser] = useAuthUser()
    const [loggedOut, setLoggedOut] = useState(authUser)
    const navigate = useNavigate()

    useEffect(()=>{
        if (!loggedOut) {
            navigate("/")
            window.location.reload()
        }
    },[loggedOut])

    function handleLogout() {
        Auth.signOut().then(() => setLoggedOut(null))
    }

    return (
        authUser &&
        <div className={styles.range}>
            <div className={styles.menu}>
                <div className={styles.item}>
                    <span className={styles.icon}><BsFillPersonLinesFill/></span>
                    <Link to={"profile"} className={styles.link}>{authUser.firstName +' '+authUser.lastName}</Link>
                </div>
                <hr className={styles.delimiter}/>
                <div className={styles.item}>
                    <div className={styles.itemLogout}>
                    <button className={"btn btn-warning"} onClick={handleLogout}><BsFillUnlockFill/>Log Out</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Menu;