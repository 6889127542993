import React from "react";
import landingImage from "../../../assets/images/Benefits-graphic-D.png";
import { Link } from "react-router-dom";
import { Logo } from "../../../components";
import {StyledLandingPage} from "./landing-page-styled.component";

const LandingPage = () => {
  return (
    <StyledLandingPage>
      <nav>
        <Logo />
      </nav>
      <div className="container page">
        <div className="info">
          <h1>
            info <span>about</span> App
          </h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam
            expedita cumque dignissimos vel fugiat dolores consectetur veritatis
            voluptas nam blanditiis sequi consequatur incidunt corrupti adipisci
            maiores, corporis odio minus obcaecati?
          </p>
          <Link to="/register" className="btn btn-hero">
            Login/Register
          </Link>
        </div>
        <img
          src={landingImage}
          alt="benefits graphic"
          className="img main-img"
        />
      </div>
    </StyledLandingPage>
  );
};

export default LandingPage;