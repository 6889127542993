import {withAuthenticator} from "@aws-amplify/ui-react";
import {useContext, useEffect} from "react";
import {UserContext} from "../../../contexts/user.context";
import {Navigate} from "react-router";
import {AmplifyProvider} from "@aws-amplify/ui-react";

const Login = ({user}) => {

    const {setUser} = useContext(UserContext)
    useEffect(()=>{
        setUser(user)
    },[])
    return (
        <AmplifyProvider>
        <>
            <Navigate to="/" replace />
        </>
        </AmplifyProvider>
    );
}
export default withAuthenticator(Login);