import './debug-page.style.scss'
import {ApproveTable} from "../../components";
import approveHeaders from "../../utils/tables/headers/approve-headers";
import {files} from "../../utils/init/files";

const DebugPage = () => {
    function f(event, comment) {
        console.log(event.target)
        console.log(comment)
    }
    return (<>
        <ApproveTable headerList={approveHeaders} dataList={files} outAction={f}/>
    </>);
}
export default DebugPage;