import uuid from "react-uuid";
import React from "react";
import {sliceFileName} from "../../../utils/functions";

const ShareTable = ({headerList, dataList, outAction, filesToShare}) => {
    /*Handlers*/
    function handleDownload(URL) {window.open(URL, "_blank", "noopener,noreferrer")}
    function handleCheckboxChange(event) {outAction(event)}
    /*JSX*/
    return (
        <>
            <table className={'table table-striped table-sm table-responsive'}>
                <thead>
                <tr className={'download-table-head'}>
                    {headerList.map((value) => <th key={value.id}>{value.text}</th>)}
                </tr>
                </thead>
                <tbody>
                {dataList.map(item => {
                    /*JSX*/
                    return (
                        <tr key={uuid()}>
                            <td>
                                <input className={"form-check-input me-2"} type={"checkbox"}
                                       id={item.filenamePresignedURL}
                                       onChange={handleCheckboxChange}
                                       checked={filesToShare.filter(value => value.fileName === item.filenamePresignedURL).length > 0}/>
                                <label className={"form-check-label"}
                                       htmlFor={item.filenamePresignedURL}>{sliceFileName(item.filenamePresignedURL)}</label>
                            </td>
                            <td>
                                <button
                                    type={"button"}
                                    className={"dc-btn-skillex-simple"}
                                    onClick={() => handleDownload(item.presignedURL)}>Download
                                </button>
                            </td>
                        </tr>)
                })}
                </tbody>
            </table>
        </>);
}
export default ShareTable;