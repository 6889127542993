import DocViewer, {DocViewerRenderers} from "react-doc-viewer";
import infoHeaders from "../../../utils/tables/headers/info-headers";
import styles from "../../../pages/Approve/approve-page.module.css";
import {useState} from "react";
import {Dialog} from "../../index";
import {sliceFileName} from "../../../utils/functions";
import React from "react";

const ApproveTable = ({dataList, headerList, outAction}) => {
    /*States*/
    const [contentModal, setContentModal] = useState({title: '', content: <></>, buttons: <></>})
    /*Constants*/
    const htmlTextArea =  document.getElementById('floatingTextarea2')
    /*Variables*/
    let comment = htmlTextArea ? htmlTextArea.value: '';
    /*Handlers*/
    function handleComment(event) {
        comment = event.target.value
    }
    function handleApproval(event) {
        outAction(event, comment)
    }
    function handleDownload(URL) {
        window.open(URL, "_blank", "noopener,noreferrer");
    }

    function handleCloseModal(){
        setContentModal({title: '', content: <></>, buttons: <></>})
    }
    /*Components*/
    const Row = (props) => {
        /*Constants*/
        const {filenamePresignedURL, presignedURL, metaDataInfo} = props
        /*Handlers*/
        function handleFileViewModal() {
            const fileExtension = filenamePresignedURL.split(".").pop().toLowerCase();

            const focusFile = {
                uri: presignedURL, fileType: fileExtension
            };

            const content = <DocViewer documents={[focusFile]}
                                       pluginRenderers={DocViewerRenderers}
                                       style={{width: 780, height: 550}}/>
            const title = "File View"
            setContentModal({content, title});
        }
        function handleDetailsModal() {
            const content = <>
                <table className={"table table-sm"}>
                    <thead>
                    <tr>
                        {infoHeaders.map((tableModal) => {
                            return <th>{tableModal.text}</th>;
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{metaDataInfo.firstName}</td>
                        <td>{metaDataInfo.lastName}</td>
                        <td>{metaDataInfo.institution}</td>
                        <td>{metaDataInfo.email}</td>
                    </tr>
                    </tbody>
                </table>
            </>;
            const title = "Document Details"
            setContentModal({title, content})
        }
        function handleApproveModal() {
            const title = "Approve Document"
            const content = <>
                <div className="form-floating">
                    <textarea className="form-control" name={"commentArea"} placeholder="Leave a comment here"
                              id="floatingTextarea2"
                              onChange={handleComment} content={comment}/>
                    <label htmlFor="floatingTextarea2">Comments</label>
                </div>
            </>;
            const buttons = <>
                <button
                    type="submit"
                    className={"btn dc-btn-submit"}
                    data-bs-dismiss="modal" aria-label="Close"
                    id={filenamePresignedURL}
                    onClick={handleApproval}>Approve
                </button>
            </>;
            setContentModal({title, content, buttons})
        }
        function handleRejectModal() {
            const title = "Reject Document"
            const content = <><p>Do you really wont to reject the file?</p></>
            const buttons = <>
                <button
                    className={"btn btn-danger"}
                    data-bs-dismiss="modal" aria-label="Close"
                    id={filenamePresignedURL}
                    onClick={handleApproval}>Reject</button>
            </>
            setContentModal({title, content, buttons})

        }
        /*JSX*/
        return (<tr key={filenamePresignedURL}>
            <td data-title="File Name">
                    <span
                        data-bs-toggle="modal" data-bs-target="#approve-modal"
                        className={styles.linkView}
                        onClick={handleFileViewModal}>{sliceFileName(filenamePresignedURL)}</span>
            </td>
            <td data-title="File Information">
                    <span
                        data-bs-toggle="modal" data-bs-target="#approve-modal"
                        className={styles.linkView}
                        onClick={handleDetailsModal}>Information</span>
            </td>
            <td data-title="Action">
                <div className={styles.buttonContainerDelimiter}>
                    <div className={"button-container"}>
                        <button
                            type={"button"}
                            className={"btn btn-sm btn-outline-secondary"}
                            onClick={()=>handleDownload(presignedURL)}>Download
                        </button>
                        <button
                            data-bs-toggle="modal" data-bs-target="#approve-modal"
                            type="button"
                            className={"dc-btn-skillex"}
                            id={filenamePresignedURL}
                            onClick={handleApproveModal}>Approve
                        </button>
                        <button
                            data-bs-toggle="modal" data-bs-target="#approve-modal"
                            className={"dc-btn-skillex-danger"}
                            id={filenamePresignedURL}
                            onClick={handleRejectModal}>Reject
                        </button>
                    </div>
                </div>
            </td>
        </tr>);
    };
    /*JSX*/
    return (
        <>
            <div className={"table-responsive"}>
                <table className={"table table-striped table-sm"}>
                    <thead>
                    <tr>
                        {headerList.map((tableApp) => {
                            return <th key={tableApp.id}>{tableApp.text}</th>;
                        })}
                    </tr>
                    </thead>
                    <tbody>
                    {dataList.map((data, index) => (<Row {...data} key={index}/>))}
                    </tbody>
                </table>
            </div>

            <Dialog content={contentModal.content} title={contentModal.title} buttons={contentModal.buttons} id={'approve-modal'} handleClose={handleCloseModal}/>
        </>
    );
}
export default ApproveTable;