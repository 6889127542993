import React, {useState, useEffect} from "react";
import Loader from "../../components/Loader/loader.component";
import DownloadService from "../../services/download.service";
import {ApproveTable, Message, ShareTable,} from "../../components";
import approveHeaders from "../../utils/tables/headers/approve-headers";
import {BsClipboard} from "react-icons/bs";
import VerifyService from "../../services/verify.service";
import "./download-page.style.scss"
import downloadHeaders from "../../utils/tables/headers/download-headers";
import {useAuthUser} from "../../hooks/requests";
import uuid from "react-uuid";
import {createMessage} from "../../utils/functions";

const DownloadPage = () => {
    /*States*/
    const [documentListToApprove, setDocumentListToApprove] = useState(null);
    const [documentListApproved, setDocumentListApproved] = useState(null);
    const [message, setMessage] = useState(null);
    const [isLoading, setLoading] = useState(false)
    const [filesToShare, setFilesToShare] = useState([])
    const [user, token] = useAuthUser()
    const [UUID, setUUID] = useState('')
    const [shareLink, setShareLink] = useState('')

    /*Variables*/
    let approvalJSON = {};

    /*Effects*/
    useEffect(() => {
        setLoading(true)
        getFiles();
    }, [])

    useEffect(() => {
        if (UUID !== '') {
            VerifyService.geneneratePresignedURL(filesToShare, UUID, token)
                .then(() => {
                    setShareLink(process.env.REACT_APP_DOMAIN_PROD + '/verified/' + UUID)
                    setMessage(
                        createMessage(
                            'Shared link created',
                            'The link for sharing the selected files was generated successfully',
                            'success'
                        )
                    )
                    setLoading(false)
                })
                .catch((error) => {
                    setLoading(false)
                    setMessage(
                        createMessage(
                            'Failed',
                            "Sharing link could not be generated: " + error.message,
                            'error'))
                });
        }
    }, [UUID])

    useEffect(() => {
        if (!shareLink) setMessage(null)
    }, [documentListApproved])

    /*Functions*/
    function getFiles() {
        const email = user.eMail
        DownloadService.getDownloadApprover(email, token)
            .then((listToApprove) => {
                /*An array is expected: empty or not */
                if (listToApprove.length > 0) setDocumentListToApprove(listToApprove);

                DownloadService.getOwnerDocuments(email, token)
                    .then(listOfApproved => {
                        /*An array is expected: empty or not */
                        if (listOfApproved.length > 0) setDocumentListApproved(listOfApproved)

                        if (!documentListApproved) {
                            setMessage(
                                createMessage(
                                    "No documents",
                                    "No documents to share. Please check and approve the documents first.",
                                    'warning'
                                )
                            )
                        }

                        setLoading(false)
                    })
                    .catch((error) => {
                        setLoading(false)
                        setMessage(
                            createMessage(
                                'Error',
                                "Data could not be loaded: " + error.message,
                                'error'))
                    });
            })
            .catch((error) => {
                setLoading(false)
                setMessage(
                    createMessage(
                        'Error',
                        "Data could not be loaded: " + error.message,
                        'error'))
            });
    }

    /*Handlers*/
    const handleApproval = (event, comment) => {
        event.preventDefault();

        setLoading(true)
        /*Reset States*/
        setDocumentListApproved(null)
        setDocumentListToApprove(null)
        setMessage(null)

        approvalJSON = {
            documentOwnerId: user.eMail,
            fileName: event.target.id,
            documentOwnerStatus: event.target.innerText === 'Approve' ? "APPROVED" : "REJECTED",
            documentOwnerComment: comment
        }

        DownloadService.setDownloadApprover(approvalJSON, token)
            .then(() => getFiles())
            .catch((error) => {
                setLoading(false)
                setMessage(
                    createMessage(
                        'Approve failed',
                        "Data could not be set: " + error.message,
                        'error'))
            });
    };

    function handleShare() {
        setUUID(uuid())
        setLoading(true)
    }

    function handleCheckboxChange(event) {
        const checked = event.target.checked;
        const selectedFile = event.target.id;
        const metadataInfo = {
            "email": documentListApproved[0].metaDataInfo.email,
            "firstName": documentListApproved[0].metaDataInfo.firstName,
            "lastName": documentListApproved[0].metaDataInfo.lastName,
            "institution": documentListApproved[0].metaDataInfo.institution,
            "typeOfCertification": documentListApproved[0].metaDataInfo.typeOfCertification
        }
        const shareData = {
            metaDataInfo: {...metadataInfo},
            fileName: selectedFile
        }
        if (checked) setFilesToShare(prevState => [...prevState, shareData])
        else setFilesToShare(prevState => prevState.filter(value => value.fileName !== selectedFile))
    }

    function handleCopyToClipboard() {
        navigator.clipboard.writeText(shareLink)
            .then(() => setMessage(
                createMessage(
                    'Success',
                    "Link was copied in clipboard!",
                    'success'))
            )
    }

    return (
        <>
            <h1 className={"title"}>Download</h1>
            <div className={"content-container"}>
                {
                    isLoading
                        ? <Loader/>
                        : <>
                            {
                                documentListToApprove &&
                                <>
                                    <div className={""}>
                                        <h4>Documents to approve</h4>
                                        <p>Please verify and approve the documents below in order to be able to download and
                                            share
                                            them.
                                            In
                                            case a document is mistaken, reject it and communicate the mistake to your
                                            institution.
                                            The document will be corrected and uploaded again.</p>
                                        <ApproveTable dataList={documentListToApprove} headerList={approveHeaders}
                                                      outAction={handleApproval}/>
                                    </div>
                                </>
                            }
                            {
                                documentListApproved &&
                                <div className={"approved-container"}>
                                    <h4>Documents to share</h4>
                                    <p>Select files and click share to share the files by link.</p>
                                    <ShareTable headerList={downloadHeaders} dataList={documentListApproved}
                                                outAction={handleCheckboxChange} filesToShare={filesToShare}/>
                                </div>
                            }
                            <div className={'dc-container-items'}>
                                {
                                    documentListApproved &&
                                    <div className={'button-container'}>
                                        <button className={"btn btn-outline-secondary"} type={'button'}
                                                onClick={handleShare}
                                                disabled={filesToShare.length === 0}>Share Selection
                                        </button>
                                    </div>
                                }
                                {
                                    shareLink &&
                                    <>
                                        <div className={'download-link'}>
                                            <div><span className="input-group-text">{shareLink}</span></div>
                                            <button className={"dc-btn-skillex-simple"} type={"button"} onClick={handleCopyToClipboard}><BsClipboard/></button>
                                        </div>
                                        <p>This link is available 24 hours.<br/>
                                            <span className={'text-danger'}>Please save the link.
                                            You will not see this link anymore by next login</span>
                                        </p>
                                    </>
                                }
                                {
                                    message &&
                                    <div className={"dc-container-center"}>
                                        <Message
                                            messageTitle={message.title}
                                            messageText={message.text}
                                            messageTypeClass={message.type}/>
                                    </div>
                                }
                            </div>
                        </>
                }
            </div>
        </>);
}
export default DownloadPage;