export function generateID(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function setConfigurationWithAuthorization(config, token) {
    let configuration = config
    if (token !== null)
        configuration = {
            ...config, options: {
                headers: {
                    ...config.options.headers,
                    "Authorization": "Bearer " + token
                }
            }
        }
    return configuration;
}

export function sliceFileName(fileNameURL){
    return fileNameURL.slice(fileNameURL.search("_") + 1)
}
export function createMessage(title, text, type){
    return {
        title:title,
        text:text,
        type:type
    }
}
export function createLog(message) {
    return {message:message}
}