import {MdVerified} from "react-icons/md";
import {FiDownload} from "react-icons/fi";
import {FiUpload} from "react-icons/fi";
import {MdOutlineVerified} from "react-icons/md";
import {groups} from "./constants/groups.enum";
import {useContext} from "react";
import {UserContext} from "../contexts/user.context";

export default function useLinks() {
    /*States*/
    const {user} = useContext(UserContext)

    /*Constants*/

    const userGroups = user
        ? user.getSignInUserSession().getAccessToken().payload["cognito:groups"]
        : null;

    /*Variables*/
    let links = [
        {id: 4, text: "verify", path: "verify", icon: <MdOutlineVerified/>}
    ];
    if(userGroups) {
        if (userGroups.includes(groups.OWNER))
            links = [
                {id: 3, text: "download", path: "download", icon: <FiDownload/>},
                ...links
            ];

        if (userGroups.includes(groups.OUSER))
            links = [
                {id: 1, text: "upload", path: "upload", icon: <FiUpload/>},
                {id: 2, text: "approve", path: "approve", icon: <MdVerified/>},
                ...links
            ];
    }

    return links;
}