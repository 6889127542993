import {NavLink} from "react-router-dom";
import styles from './navigation.module.css';
import useLinks from "../../utils/links";
/**
 * forceUpdate() is a class method and it will throw an error although it executes.
 * It also has the advantage that it triggers the build-in pop-up.
 * Alternatively, an updateState can be used to trigger a refresh of the page.
 **/
const Navigation = () => {
    const links = useLinks()
    return (
        <div className={styles.navLinks}>
            {links.map((link) => {
                const {text, path, id, icon} = link;
                return (
                    <NavLink
                        to={path}
                        className={({isActive}) => isActive ? styles.navLink + " " + styles.active  : styles.navLink}
                        key={id}>
                        <span className={styles.icon}>{icon}</span>
                        {text}
                    </NavLink>
                );
            })}
        </div>
    );
};

export default Navigation;