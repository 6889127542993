import React from "react";
import {
    ApproveTable,
    Message
} from "../../components";
import {useEffect, useState} from "react";
import ApproveService from "../../services/approve.service";
import Loader from "../../components/Loader/loader.component";
import approveHeaders from "../../utils/tables/headers/approve-headers";
import {useAuthUser} from "../../hooks/requests";
import {createMessage} from "../../utils/functions";

const ApprovePage = () => {
    /*States*/
    const [files, setFiles] = useState([]);
    const [message, setMessage] = useState(null);
    const [isLoading, setLoading] = useState(null)
    const [user, token] = useAuthUser()
    /*Variables*/
    let approveJSON = {};

    /*Effects*/
    useEffect(() => {
        setLoading(true)
        getFiles();
    }, [user.id]);

    /*Functions*/
    function getFiles() {
        ApproveService.getApprovers(user.id, token)
            .then((listToApprove) => {
                setLoading(false)
                const noData = handleNoData(listToApprove);
                if (noData) setMessage(noData)
                else setFiles(listToApprove)
            })
            .catch((error) => {
                setLoading(false)
                setMessage(
                    createMessage(
                        'Error',
                        "Data could not be loaded: " + error.message,
                        'error'))
            });
    }

    /*Handlers*/
    const handleApproval = (event, comment) => {
        event.preventDefault();
        setLoading(true)
        approveJSON = {
            approverId: user.id,
            fileName: event.target.id,
            assigneApprovedStatus: event.target.innerText === 'Approve' ? "APPROVED" : "REJECTED",
            metaDataInfo: {...files[0].metaDataInfo},
            comment: comment
        }
        ApproveService.setApprovers(approveJSON, token)
            .then(() => getFiles())
            .catch((error) => {
                setLoading(false)
                setMessage(
                    createMessage(
                        'Error',
                        "Data could not be loaded: " + error.message,
                        'error'))
            });
    };
    const handleNoData = (dataToCheck) => {
        if (dataToCheck.length === 0) return createMessage('NO DATA', 'There are no documents to be approved!', 'warning');
        if (dataToCheck.filenamePresignedURL === null) return createMessage('NO DATA', 'The fileName is not found!', 'warning');
        if (dataToCheck.presignedURL === null) return createMessage('NO DATA', 'The Download URL is not set!', 'warning');
        return null;
    }
    /*JSX*/
    return (
        <>
            <h1 className={"title"}>Approve</h1>
            <div className={"content-container"}>
                {(isLoading && <div className={"dc-loader-block"}><Loader/></div>)
                    || (message
                        ? <div className={"dc-container-center"}><Message messageTitle={message.title}
                                                                          messageText={message.text}
                                                                          messageTypeClass={message.type}/></div>
                        : <ApproveTable dataList={files} headerList={approveHeaders} outAction={handleApproval}/>)}
            </div>
        </>);
};

export default ApprovePage;