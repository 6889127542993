import ApiService from "./http.service";
import {setConfigurationWithAuthorization} from "../utils/functions";

/*Request config*/
const config = {
    api: process.env.REACT_APP_API_UPLOAD_SERVICE_PROD,
    options: {
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "Content-Type,X-Amz-Date,X-Amz-Security-Token,Authorization,X-Api-Key,X-Requested-With,Accept,Access-Control-Allow-Methods,Access-Control-Allow-Origin,Access-Control-Allow-Headers",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "DELETE,GET,HEAD,OPTIONS,PATCH,POST,PUT",
            "X-Requested-With": "*"
        },
    },
}
/*http requests*/
function getApprovals(token=null) {
    const endpoint = '/upload/approver'
    let configuration = setConfigurationWithAuthorization(config, token);
    return ApiService.httpGet(endpoint, configuration)
}
function getApprovalGroups(token=null) {
    const endpoint = '/upload/approverGroup'
    let configuration = setConfigurationWithAuthorization(config, token);
    return ApiService.httpGet(endpoint, configuration)
}

function postDocument (data, token=null) {
    const endpoint = '/upload/document'
    let configuration = setConfigurationWithAuthorization(config, token);
    return ApiService.httpPost(endpoint, data, configuration)
}

const UploadService = {getApprovals, getApprovalGroups, postDocument};
export default UploadService;