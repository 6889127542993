import React from "react";
import { Link } from "react-router-dom";
import StyledError from "./error-styled.component";

const ErrorPage = () => {
  return (
    <StyledError className="full-page">
      <div>
        <h3>No access granted</h3>
        <p>You don´t have access to this page </p>
        <Link to="/">back home</Link>
      </div>
    </StyledError>
  );
};

export default ErrorPage;