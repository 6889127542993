import landingImage from "../../assets/images/Benefits-graphic-D.png";

const HomePage = () => {
    return (
        <div className="container page">
            <div className="info">
                <h1>
                    info <span>about</span> App
                </h1>
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam
                    expedita cumque dignissimos vel fugiat dolores consectetur veritatis
                    voluptas nam blanditiis sequi consequatur incidunt corrupti adipisci
                    maiores, corporis odio minus obcaecati?
                </p>
            </div>
            <img
                src={landingImage}
                alt="benefits graphic"
                className="img main-img"
            />
        </div>
    );
};

export default HomePage;