/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: process.env.REACT_APP_AWS_REGION_PROD,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION_PROD,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID_PROD,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID_PROD,
  oauth: {},
  aws_cognito_username_attributes: JSON.parse(process.env.REACT_APP_AWS_COGNITO_USERNAME_ATTRIBUTES_PROD),
  aws_cognito_social_providers: JSON.parse(process.env.REACT_APP_AWS_COGNITO_SOCIAL_PROVIDERS_PROD),
  aws_cognito_signup_attributes: JSON.parse(process.env.REACT_APP_AWS_COGNITO_SIGNUP_ATTRIBUTES_PROD),
  aws_cognito_mfa_configuration: JSON.stringify(process.env.REACT_APP_AWS_COGNITO_MFA_CONFIGURATION_PROD),
  aws_cognito_mfa_types: JSON.stringify(process.env.REACT_APP_AWS_COGNITO_MFA_TYPES_PROD),
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: JSON.parse(process.env.REACT_APP_AWS_COGNITO_PASSWORD_PROTECTION_SETTINGS_MIN_LENGTH_PROD),
    passwordPolicyCharacters: JSON.parse(process.env.REACT_APP_AWS_COGNITO_PASSWORD_PROTECTION_SETTINGS_CHARACTERS_PROD),
  },
  aws_cognito_verification_mechanisms: JSON.parse(process.env.REACT_APP_AWS_COGNITO_VERIFICATION_MECHANISMS_PROD),
};
export default awsmobile;