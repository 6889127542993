import ApiService from "./http.service";
import {setConfigurationWithAuthorization} from "../utils/functions";

const config = {
    api: process.env.REACT_APP_API_DOWNLOAD_SERVICE_PROD,
    options: {
        headers: {
            'Content-Type': 'application/json' }
    }
}

function getOwnerDocuments(email, token=null) {
    const endpoint = '/download/documents/approved?email=' + email
    let configuration = setConfigurationWithAuthorization(config, token);
    return ApiService.httpGet(endpoint, configuration)
}
function getDownloadApprover(email, token=null) {
    const endpoint = "/download/documents?email="+ email
    let configuration = setConfigurationWithAuthorization(config, token);
    return ApiService.httpGet(endpoint, configuration)
}
function setDownloadApprover(data, token=null) {
    const endpoint = "/download/approval"
    let configuration = setConfigurationWithAuthorization(config, token);
    return ApiService.httpPost(endpoint, data, configuration);
}

const DownloadService = {
  getOwnerDocuments,
  setDownloadApprover,
  getDownloadApprover
};

export default DownloadService;