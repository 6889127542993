export class AuthenticatedUser {
    constructor(id, firstName, lastName, eMail, userName, groups = [], expired) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.eMail = eMail;
        this.userName = userName;
        this.groups = groups
        this.expired = expired;
    }
}