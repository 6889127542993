import './verify-list-page.style.scss'
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import VerifyService from "../../../services/verify.service";
import {Logo, Message} from "../../../components";
import React from "react";
import {createMessage, sliceFileName} from "../../../utils/functions";

const VerifyListPage = () => {
    const verifiedParam = useParams();
    const [documentList, setDocumentList] = useState([]);
    const [message, setMessage] = useState(null)

    useEffect(() => {
        VerifyService.getOwnerDocuments(verifiedParam.uuid)
            .then(documents => setDocumentList([...documents]))
            .catch(error => {
                setMessage(
                    createMessage(
                        'Failed',
                        "Documents are not loaded: " + error.message,
                        'error'))
            });
    }, [])

    function handleDownload(url) {
        window.open(url, "_blank", "noopener, noreferrer");
    }

    return (
        documentList.length > 0 &&
        <>
            <div className={'nav-container'}>
                <Logo white={true}/>
                <span className={'verify-list-user'}>{documentList[0].metaDataInfo.institution}</span>
            </div>
            <div className={"header-placeholder"}/>
            <div className={'container'}>
                <div className={'content-container'}>
                    {message
                        ? <Message messageTitle={message.title}
                                   messageText={message.text}
                                   messageTypeClass={message.type}/>
                        : <>
                            <h1 className={'title verify-list-title '}>
                                Documents of <span className={'verify-list-title-user'}>
                                {documentList[0].metaDataInfo.firstName} {documentList[0].metaDataInfo.lastName}</span>
                            </h1>
                            <p className={'subtitle '}>These documents are verified and you can download the lastest
                                version</p>
                            <div className={'verify-list-items '}>
                                {documentList.map((document, index) => {
                                    return (<div className={'verify-list-item '} key={index}>
                                        <span className={'verify-list-value'}>{sliceFileName(document.fileName)}</span>
                                        <span>{documentList[0].metaDataInfo.typeOfCertification}</span>
                                        <span className={'verify-list-buttons'}>
                                            <button className={'dc-btn-skillex'}
                                                    type={"button"}
                                                    onClick={() => handleDownload(document.preSignedUrl)}>Download</button>
                                        </span>
                                    </div>)
                                })}
                            </div>
                        </>}
                </div>
            </div>
        </>);
}
export default VerifyListPage;