import React from "react";

const Dialog = ({title, content, buttons, handleClose, id}) => {

    const closeModal = () => handleClose()
    return (
        <div className="modal fade" data-bs-backdrop="static" id={id} tabIndex="-1" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}/>
                    </div>
                    <div className="modal-body">
                        {content}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={closeModal}>Close</button>
                        {buttons}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dialog;